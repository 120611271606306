/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap"); */

@font-face {
  font-family: "Adobe Clean";
  src: url("assets/fonts/AdobeClean-Regular.ttf");
}

@font-face {
  font-family: "Arial";
  src: url("assets/fonts/arial.ttf");
}
@font-face {
  font-family: "Arial Narrow";
  src: url("assets/fonts/arialnarrow.ttf");
}
@font-face {
  font-family: "AlegreyaSC";
  src: url("assets/fonts/AlegreyaSC-Regular.ttf");
}
@font-face {
  font-family: "Aleo";
  src: url("assets/fonts/Aleo-Regular.ttf");
}
@font-face {
  font-family: "Amaranth";
  src: url("assets/fonts/Amaranth-Regular.ttf");
}
@font-face {
  font-family: "Antonio";
  src: url("assets/fonts/Antonio-Regular.ttf");
}
@font-face {
  font-family: "Asap";
  src: url("assets/fonts/Asap-Regular.ttf");
}
@font-face {
  font-family: "Avenir";
  src: url("assets/fonts/avenir_lt_std_45_book.ttf");
}
@font-face {
  font-family: "Bebas Neue";
  src: url("assets/fonts/BebasNeue-Regular.ttf");
}
@font-face {
  font-family: "Cambo";
  src: url("assets/fonts/Cambo-Regular.ttf");
}
@font-face {
  font-family: "Georgia";
  src: url("assets/fonts/Georgia.ttf");
}
@font-face {
  font-family: "Great Vibes";
  src: url("assets/fonts/GreatVibes-Regular.ttf");
}
@font-face {
  font-family: "Helvetica";
  src: url("assets/fonts/Helvetica.ttf");
}
@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-Regular.ttf");
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Muli";
  src: url("assets/fonts/Muli-Regular.ttf");
}
@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-VariableFont_wdth\,wght.ttf");
}
@font-face {
  font-family: "Oswald";
  src: url("assets/fonts/Oswald-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Playfair Display";
  src: url("assets/fonts/PlayfairDisplay-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Raleway";
  src: url("assets/fonts/Raleway-Regular.ttf");
}
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Rockwell";
  src: url("assets/fonts/ROCK.TTF");
}
@font-face {
  font-family: "Segoe UI";
  src: url("assets/fonts/SEGOEUI.TTF");
}
@font-face {
  font-family: "Times New Roman";
  src: url("assets/fonts/times\ new\ roman.ttf");
}
@font-face {
  font-family: "Verdana";
  src: url("assets/fonts/Verdana.ttf");
}

* {
  padding: 0;
  margin: 0;
  overscroll-behavior: none;
  font-family: Poppins, sans-serif;
}
.MuiButtonBase-root.MuiButton-containedPrimary.Mui-disabled {
  background-color: #0d98ba;
  color: #fff;
  opacity: 0.6;
}
#root .MuiTabScrollButton-root.Mui-disabled {
  opacity: 0.1;
}

.swal2-popup {
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding-bottom: 2em;
}

body b,
body strong {
  font-weight: 500;
}

.ql-editor {
  min-height: 150px;
}

#root .swiper-button-prev,
#root .swiper-button-next {
  background: #fff;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  box-shadow:
    2px 2px 10px rgba(0, 0, 0, 0.1),
    0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  top: calc(50% - 0.5rem) !important;
}

#root .swiper-button-prev:after,
#root .swiper-button-next:after {
  font-size: 1.2rem;
  font-weight: bold;
}

#root .swiper-button-prev:after {
  margin-right: 0.2rem;
}

#root .swiper-button-next:after {
  margin-right: -0.2rem;
}

#root .swiper-button-prev.swiper-button-disabled,
#root .swiper-button-next.swiper-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: auto;
}

a {
  color: #0d98ba;
  text-decoration: none !important;
}

a[href="{{DocumentLink}}"] {
  text-decoration: none !important;
  padding: 10px 15px !important;
  border-radius: 3px !important;
  font-size: 16px !important;
  display: inline-block !important;
  background: #0d98ba !important;
  color: #fff !important;
}

/* Basic scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05); /* Subtle translucent dark track */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Slightly less visible thumb */
  border-radius: 4px;
}

/* Hover effect for scrollbar */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3); /* Slightly more visible on hover */
}
