.dsg-selection-row-marker-container,
.dsg-selection-col-marker-container {
  display: none;
}

.dsg-add-row {
  font-size: 0;
}

.dsg-add-row > input {
  display: none;
}

.dsg-cell.dsg-cell-header.File-Name .dsg-cell-header-container:after,
.dsg-cell.dsg-cell-header.Recipient-Name .dsg-cell-header-container:after,
.dsg-cell.dsg-cell-header.Email-Address .dsg-cell-header-container:after,
.dsg-cell.dsg-cell-header.WhatsApp-Number .dsg-cell-header-container:after {
  content: "*";
  color: #d32f2f;
  margin-left: 0.1rem;
}

.dsg-cell.has-error {
  background-color: #fdeded;
  color: #5f2120;
}
